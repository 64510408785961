var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"navigate-space"},[(_vm.testIndex != 0 && _vm.testsFilter.length > 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ir al anterior`),expression:"`Ir al anterior`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"navigate-left",attrs:{"size":"sm","variant":"none"},on:{"click":_vm.previousTabs}},[_c('b-icon',{attrs:{"icon":"chevron-left"}})],1):_vm._e()],1),_c('strong',{staticClass:"border-bottom pb-1"},[_vm._v("Evaluar "+_vm._s(_vm.test.title))]),_c('div',{staticClass:"navigate-space"},[(_vm.testIndex != _vm.testsFilter.length - 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ir al siguiente`),expression:"`Ir al siguiente`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"navigate-right",attrs:{"size":"sm","variant":"none"},on:{"click":_vm.nextTabs}},[_c('b-icon',{attrs:{"icon":"chevron-right"}})],1):_vm._e()],1)]),(_vm.user_id != null)?_c('div',{staticClass:"mt-2 border-bottom"},[_c('div',{staticClass:"ml-2 text-left mb-1"},[(
          !_vm.evaluateeTest ||
          (_vm.evaluateeTest && _vm.evaluateeTest.start_time == null)
        )?_c('span',[_vm._v(" Estado: "),_c('strong',{staticClass:"text-red"},[_vm._v(" No iniciado ")])]):(_vm.evaluateeTest)?_c('span',[_vm._v(" Estado: Iniciado el "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("FormatToDateTime")(_vm.evaluateeTest.start_time))+" ")])]):_vm._e()])]):_vm._e(),(_vm.evaluateeTest && _vm.evaluateeTest.student_attachments.length > 0)?_c('div',{staticClass:"mt-2 mb-2 border-bottom text-left"},[[_c('span',{staticClass:"ml-2"},[_vm._v(" Adjuntos del Evaluado: ")]),_c('FileContainer',{staticClass:"ml-2",attrs:{"Files":_vm.evaluateeTest.student_attachments,"allows_crud":false}})]],2):_vm._e(),_c('div',{staticClass:"mt-0"},[_c('b-form-group',{staticClass:"mb-0 mx-2 py-1 text-left border-bottom",attrs:{"label":_vm.evaluateeTest && _vm.evaluateeTest.manual_score == null
          ? 'Puntaje propuesto'
          : 'Puntaje Máximo',"label-cols":"4","label-cols-sm":"8"}},[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"automated-score"},[(_vm.evaluateeTest && _vm.evaluateeTest.manual_score == null)?_c('span',[_vm._v(" "+_vm._s(_vm.automatedScore)+" / ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.test.max_score)+" ")])])]),_c('div',{staticClass:"d-flex mb-0 mx-2 py-1 text-left border-bottom"},[_c('div',{staticClass:"mt-1"},[_vm._v("Puntaje asignado:")]),_c('div',{staticClass:"mr-0 ml-auto"},[_c('div',{staticClass:"d-flex"},[(
              _vm.evaluateeTest &&
              _vm.evaluateeTest.manual_score != null &&
              _vm.evaluatorCanQualify
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Aplicar Puntaje Automático'
            ),expression:"\n              'Aplicar Puntaje Automático'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 mr-1 p-0",attrs:{"variant":"none"},on:{"click":function($event){return _vm.patchEvaluateeTest({ manual_score: null })}}},[_c('b-icon',{staticStyle:{"color":"white"},attrs:{"icon":"arrow-repeat"}})],1):_vm._e(),_c('b-form-input',{staticClass:"mr-1 manual-score-input",attrs:{"id":"manual-score-input","type":"number","disabled":!_vm.user_id || isNaN(_vm.evaluatee_test.id) || !_vm.evaluatorCanQualify,"min":"0"},model:{value:(_vm.evaluatee_test.manual_score),callback:function ($$v) {_vm.$set(_vm.evaluatee_test, "manual_score", _vm._n($$v))},expression:"evaluatee_test.manual_score"}})],1)])]),(
        _vm.evaluatee_test &&
        _vm.institution &&
        _vm.institution.internal_use_id != 'duoc_uc'
      )?_c('div',{staticClass:"instrument-grade-input border-bottom mx-2 pt-1 pb-1"},[_c('span',{staticClass:"text-left"},[_vm._v("Nota del "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+": ")]),_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.getInstrumentFinalScore())+" ")])]):_vm._e(),_c('div',{staticClass:"border-bottom"},[(_vm.user_id && _vm.institution && _vm.evaluatorCanQualify)?_c('div',{staticClass:"btn-container ml-2"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 mb-2 mr-2 w-50",class:{ 'w-100': _vm.evaluatee_test.is_published },attrs:{"size":"sm"},on:{"click":function($event){return _vm.patchQuestionTestScore(_vm.evaluatee_test.manual_score)}}},[_vm._v(" Guardar ")]),(!_vm.evaluatee_test.is_published && _vm.missingRubricCriteria == 0)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            `Publicar ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )}`
          ),expression:"\n            `Publicar ${$getVisibleNames(\n              'evaluations2.test',\n              false,\n              'Instrumento'\n            )}`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"btn-save mt-2 ml-2 mr-2 w-50",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.publicInstrument(_vm.evaluatee_test.manual_score)}}},[_vm._v(" Publicar ")]):_vm._e(),(!_vm.evaluatee_test.is_published && _vm.missingRubricCriteria > 0)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive",value:(
            `Faltan ${_vm.missingRubricCriteria} ${_vm.$getVisibleNames(
              'manual.dimension',
              true,
              'Dimensiones'
            )} por evaluar`
          ),expression:"\n            `Faltan ${missingRubricCriteria} ${$getVisibleNames(\n              'manual.dimension',\n              true,\n              'Dimensiones'\n            )} por evaluar`\n          ",modifiers:{"v-danger":true,"top":true,"noninteractive":true}}],staticClass:"w-50 mr-3"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 mr-2",attrs:{"variant":"primary","size":"sm","disabled":_vm.missingRubricCriteria > 0}},[_vm._v(" Publicar ")])],1):_vm._e()],1):_vm._e(),(
          _vm.user_id &&
          _vm.institution &&
          _vm.evaluatorCanQualify &&
          _vm.newTestGroup &&
          !isNaN(_vm.evaluatee_test.id)
        )?_c('div',{staticClass:"btn-container ml-2"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 mb-2 mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`
            )}}},[_vm._v(" Replicar nota al grupo ")]),_c('b-modal',{attrs:{"id":`copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`,"title":"Seleccionar Evaluados","size":"lg","hide-footer":""},on:{"hide":() => {
              _vm.selected_users = [];
              _vm.select_all = false;
              _vm.select_copy_feedback = false;
            }}},[_c('GenericBTable',{attrs:{"display_id":`GenericBTable-copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`,"items":_vm.filterListReplyScore,"fields":_vm.fields,"columns_display":false,"show_pagination":true,"search_filter":false,"default_striped":false,"default_borderless":false,"pagination":100},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{on:{"change":_vm.selectAllUsers},model:{value:(_vm.select_all),callback:function ($$v) {_vm.select_all=$$v},expression:"select_all"}})]},proxy:true},{key:"cell(selected)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-checkbox',{staticClass:"ml-auto mr-auto",attrs:{"checked":_vm.selected_users.includes(row.item.id)},on:{"change":function($event){return _vm.changeSelected(row.item)}}})],1)]}},{key:"cell(first_name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.user_data ? row.item.user_data.identifier : "")+" "+_vm._s(row.item.first_name)+" "+_vm._s(row.item.last_name)+" ")]}}],null,false,791259309)}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-0 d-flex"},[_c('b-form-checkbox',{staticClass:"m-0 mt-1 mr-3",model:{value:(_vm.select_copy_feedback),callback:function ($$v) {_vm.select_copy_feedback=$$v},expression:"select_copy_feedback"}},[_vm._v(" Copiar Retroalimentación ")]),(_vm.selected_users.length > 0 && _vm.show_button_reply_score)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `${_vm.selected_users.length} evaluados seleccionados.`
                ),expression:"\n                  `${selected_users.length} evaluados seleccionados.`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"size":"sm"},on:{"click":_vm.replcateTestScore}},[_vm._v(" Aplicar ")]):_c('b-button',{attrs:{"size":"sm","disabled":""}},[_vm._v(" Aplicar")])],1)])],1)],1):_vm._e()]),(
        _vm.test.student_can_upload_attachments &&
        _vm.evaluateeTest &&
        (_vm.evaluateeTest.evaluator_attachments.length > 0 ||
          _vm.evaluatorCanQualify)
      )?_c('div',{staticClass:"mt-2 mb-1 text-left"},[[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-2"},[_vm._v("Adjunto del Evaluador:")]),(
              _vm.evaluatorCanQualify &&
              _vm.evaluateeTest.evaluator_attachments.length < 5
            )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(`Adjuntar Archivo`),expression:"`Adjuntar Archivo`",modifiers:{"v-secondary":true}}],staticClass:"ml-2 text-white pt-0",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-select-file-${_vm.test.id}-${_vm.user_id}`)}}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-select-file-${_vm.test.id}-${_vm.user_id}`,"title":"Seleccionar Archivos","hide-footer":""}},[_c('FileSelector',{on:{"created":_vm.slotCreatedFile}})],1),_c('div',{class:{
            'file-container-special-scroll':
              _vm.evaluateeTest.evaluator_attachments.length > 2,
          }},[(_vm.evaluateeTest)?_c('FileContainer',{staticClass:"ml-2",attrs:{"Files":_vm.evaluateeTest.evaluator_attachments,"allows_crud":_vm.evaluatorCanQualify},on:{"deleteFile":_vm.slotDeleteEvaluatorFile}}):_vm._e()],1)]],2):_vm._e(),(_vm.evaluatee_test)?_c('div',{staticClass:"mx-2"},[_vm._m(0),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
          _vm.user_id && _vm.institution && _vm.evaluatorCanQualify
            ? 'Editar Retroalimentación'
            : ''
        ),expression:"\n          user_id && institution && evaluatorCanQualify\n            ? 'Editar Retroalimentación'\n            : ''\n        ",modifiers:{"v-secondary":true}}],staticClass:"instrument-form-feedback rich-text-content mb-2 mt-1",domProps:{"innerHTML":_vm._s(_vm.evaluatee_test.feedback)},on:{"click":function($event){if (_vm.user_id && _vm.institution && _vm.evaluatorCanQualify)
            _vm.$bvModal.show(
              `edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`
            );}}}),_c('b-modal',{attrs:{"id":`edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`,"title":"Editar Retroalimentación","size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.evaluatee_test,"Text":_vm.evaluatee_test.feedback,"permit_blank":true},on:{"save":_vm.patchFeedback,"close":function($event){return _vm.$bvModal.hide(
              `edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`
            )}}})],1)],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('span',[_vm._v("Retroalimentación:")])])
}]

export { render, staticRenderFns }